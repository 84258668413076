import React from "react";
import Contacts from "../components/contacts/Contacts";

const ContactsPage = () => {
  return (
    <div>
      <Contacts />
    </div>
  );
};

export default ContactsPage;
