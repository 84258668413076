import React from "react";
import ProductInfo from "../components/products/ProductInfo";

const ProductPage = () => {
  return (
    <div>
      <ProductInfo />
    </div>
  );
};

export default ProductPage;
