export const navs = [
  {
    _id: 0,
    title: "Home",
    url: "/",
  },
  {
    _id: 1,
    title: "About Us",
    url: "/about",
  },
  {
    _id: 2,
    title: "Projects",
    url: "/projects",
  },
  {
    _id: 3,
    title: "Contacts",
    url: "/contacts",
  },
  {
    _id: 4,
    title: "Our Services",
    url: "/services",
  },
  {
    _id: 5,
    title: "Painting Tips",
    url: "/painting-tips",
  },
  {
    _id: 6,
    title: "faqs",
    url: "/FAQS",
  },
  {
    _id: 7,
    title: "Help",
    url: "/help",
  },
];
